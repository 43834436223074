import axios from 'axios';
import store from '@/store';
import router from '@/router';
import auth from './auth';
const AUTH_AXIOS = axios.create({
    baseURL: '/api/auth',//TODO: переделать на данные из конфига
    timeout: 3000
});

const IDENT_AXIOS = axios.create({
    baseURL: '/api/ident',//TODO: переделать на данные из конфига
    timeout: 3000
});

const AXIOS = axios.create({
    baseURL: '/api/feed',//TODO: переделать на конфигурацию
    timeout: 3000
});


AXIOS.interceptors.request.use(config => {
    const token = store.authToken;
    const tokenType = store.tokenType;
    if (token!= null && tokenType != null){
        config.headers.Authorization = tokenType + ' ' + token;
    }
    return config;
});

AXIOS.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null;
    if (status == 401){
        return auth.refreshToken().then(_ => {
            error.config.headers['Authorization'] = store.tokenType + ' ' + store.authToken;
            error.config.baseURL = undefined;
            return AXIOS.request(error.config);
        });
    }
    return Promise.reject(error);
});

export {AXIOS, AUTH_AXIOS, IDENT_AXIOS};

