import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './components/App';
import router from './router';
import {BootstrapVue,BootstrapVueIcons} from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import store from './store.js';
import './theme/custom.scss';

Vue.config.productionTip = false;

// Bootstrap
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);

new Vue({
    el: '#app',
    router,
    data: store,
    render: h => h(App)
});
