
const store = {
    authToken: null,
    isAuthRefreshing: false,
    refreshingCall: null,
    get login(){
        return localStorage['user-login'];
    },
    set login(login){
        if (login == null){
            localStorage.removeItem('user-login');
        }
        else {
            localStorage['user-login'] = login;
        }
    },
    get refreshToken(){
        return localStorage['refresh-token'];
    },
    set refreshToken(token){
        if (token == null){
            localStorage.removeItem('refresh-token');
        }
        else {
            localStorage['refresh-token'] = token;
        }
    }
};

export default store;
