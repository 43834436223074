import {AUTH_AXIOS} from './backend-config';
import router from '../router';
import store from '@/store';

export default {
    login(username, password){
        return AUTH_AXIOS.post('/login', {}, {
            headers: {
                'Authorization': 'Basic ' + btoa(`${username}:${password}`)
            }
        });
    },
    logout(login, refreshToken){
        return AUTH_AXIOS.post('/logout', {
            login,
            refreshToken
        }, {});
    },
    refreshToken() {
        if (store.isAuthRefreshing) {
            return store.refreshingCall;
        }
        if (store.refreshToken == null) {
            router.push('/login');
            return Promise.reject("refresh token is not present");
        }
        store.isAuthRefreshing = true;
        const refreshingCall = AUTH_AXIOS.put('/refresh', {
            login: store.login,
            refreshToken: store.refreshToken
        }).then(resp => {
            store.refreshToken = resp.data['refresh_token'];
            store.tokenType = resp.data['token_type'];
            store.authToken = resp.data['access_token'];
            store.isAuthRefreshing = false;
            return Promise.resolve(true);
        }).catch(e => {
            if (e.response != null && (e.response.status == 401 || e.response.status == 404)){
                store.refreshToken = null;
                router.push('/login');
            }
            store.isAuthRefreshing = false;
            return Promise.reject(e);
        });
        store.refreshingCall = refreshingCall;
        return refreshingCall;
    }

}
