<template>
    <div>
        <b-navbar v toggleable="lg" type="dark" variant="primary">
            <b-navbar-nav>
                <template v-if="this.$root.$data.refreshToken == null">
                    <b-nav-item v-if="this.$root.$data.refreshToken == null" v-bind:to="'/login'">Вход</b-nav-item>
                </template>
                <template v-else>
                    <b-nav-item v-bind:to="'/'">Главная</b-nav-item>
                    <b-nav-item v-bind:to="'/feed'">Список уведомлений</b-nav-item>
                    <b-nav-item @click="logoutClicked">Выход</b-nav-item>
                </template>
            </b-navbar-nav>
        </b-navbar>
        <b-container class="pt-2">
            <router-view></router-view>
        </b-container>
    </div>
</template>
<script>
    import auth from '../modules/auth';
    export default {
        name: "app",
        mounted() {
          if (this.$root.authToken == null) {
            auth.refreshToken();
          }
        },
        methods: {
            logoutClicked(){
                auth.logout(this.$root.$data.login, this.$root.$data.refreshToken)
                    .then(resp => {
                        this.$root.$data.login = null;
                        this.$root.$data.refreshToken = null;
                        this.$router.push('/login');
                    })
                    .catch(err => {
                        if (err.response.status == 401) {
                            this.$root.$data.login = null;
                            this.$root.$data.refreshToken = null;
                            this.$router.push('/login');
                        }
                    });
            }
        }
    }
</script>
