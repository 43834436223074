<template>
    <b-form v-if="feedItemData != null" @submit.stop.prevent="onSubmit">
        <b-form-group label="Заголовок">
            <b-form-input v-model="$v.feedItemData.title.$model"
                :state="$v.feedItemData.title.$dirty ? !$v.feedItemData.title.$error : null"
                :disabled="disabled"
            ></b-form-input>
        </b-form-group>
        <b-form-group label="Подзаголовок">
            <b-form-textarea v-model="feedItemData.summary"
                :disabled="disabled"
                name="summary"
            ></b-form-textarea>
        </b-form-group>
        <b-form-group label="Изображение">
            <b-form-file class="my-2" @input="loadImageFromAttachment" v-model="fileUploadData"></b-form-file>
            <b-form-input type="url" v-if="fileUploadData == null" v-model="$v.feedItemData.image.$model"
                          :state="$v.feedItemData.image.$dirty ? !$v.feedItemData.image.$error : null"
                          :disabled="disabled"
            ></b-form-input>
            <img class="mt-2 mw-100" v-if="isValidURL(imageUrl)" :src="imageUrl">
        </b-form-group>
        <b-form-group label="Содержимое HTML">
            <!-- через v-if данные элементы работают криво в связке, прятать trumbowyg тоже плохая идея -->
            
            <div>
                <trumbowyg
                    v-model="$v.feedItemData.content_html.$model" name="HTML content"></trumbowyg>
            </div>
            <b-form-invalid-feedback v-if="$v.feedItemData.content_html.$error">
                Содержимое HTML должно указываться, если не указано текстовое содержимое
            </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Статус публикации" v-if="feedItemData.publishStatus !== 'PUBLISHED'">
          <b-form-select
              v-model="$v.feedItemData.publishStatus.$model"
              :state="$v.feedItemData.publishStatus.$dirty ? !$v.feedItemData.publishStatus.$error : null"
              :disabled="disabled"
              class="border-secondary">
            <option value="PUBLISH_NOW">Опубликовать сейчас</option>
            <option value="PUBLISH_DELAY">Отложить публикацию</option>
            <option value="NO_PUBLISH">Не публиковать</option>
          </b-form-select>
          <b-form-invalid-feedback v-if="$v.feedItemData.publishStatus.$error">
            Пожалуйста, укажите статус публикации
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Город">
            <b-form-select
                v-model="feedItemData.city"
                class="border-secondary"
            :options="cities">
            </b-form-select>
        </b-form-group>
        <b-form-group label="Дата публикации" v-if="feedItemData.publishStatus === 'PUBLISH_DELAY'">
          <b-form-input type="datetime-local" v-model="publishDateTime" :min="curTime.format('YYYY-MM-DDTHH:mm')" :disabled="disabled" v-if="IS_DATETIME_SUPPORTED"></b-form-input>
          <template v-else>
            <b-form-input type="date" v-model="publishDate" :min="curTime.format('YYYY-MM-DD')"
                          :disabled="disabled"
            ></b-form-input>
            <b-form-input type="time" step="1" v-model="publishTime" :disabled="disabled" :min="minPublishTime(feedItemData.date_published)"
            ></b-form-input>
          </template>
        </b-form-group>
        <b-button type="submit" :disabled="disabled || submitting || $v.feedItemData.$anyError">
            Сохранить
        </b-button>
        <b-alert class="mt-2" variant="info" :show="submitting">
            Сохранение...
        </b-alert>
      </b-form>
      <div v-else>

      </div>
</template>
<script>
import {required, requiredUnless, helpers} from "vuelidate/lib/validators";
import { isWebUri } from 'valid-url'
import ident from '../../modules/ident'
const url = helpers.withParams(
    { type: 'url' },
    (value) => !helpers.req(value) || !!isWebUri(value)
);

import feed from '../../modules/feed';
import moment from 'moment';
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';

const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

let isDatetimeSupported = false;
let dateSupportCheck = function() {
  var input = document.createElement('input');
  var value = 'a';
  input.setAttribute('type', 'datetime-local');
  input.setAttribute('value', value);
  isDatetimeSupported = (input.value !== value);
};
dateSupportCheck();

export default {
  name: 'feed-item-edit',
  components:{
    trumbowyg: Trumbowyg
  },
  created() {
    this.IS_DATETIME_SUPPORTED = isDatetimeSupported;
    this.getCities();
  },
  mounted() {
    if (this.creating){
      //создать надо поля ручками, иначе vuelidate начинает вытворять дичь
      this.feedItemData = {
        id: null,
        content_html: null,
        title: null,
        url: null,
        image: null,
        date_published: null,
        publishStatus: null,
        city: null,
      };
    }
    else if (this.$route.params.id != null){
      this.reloadFeedItemData(this.$route.params.id);
    }
  },
  data(){
    return {
      curTime: moment(),
      submitting: false,
      feedItemData: null,
      fileUploadData: null,
      fileUploadDataUrl: null,
      cities: [],
    };
  },
  props: {
    creating: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations(){
    return {
      feedItemData: {
        id: {
          //required
        },
        title: {
          required
        },
        url: {
          url
        },
        image: {
          url
        },
        publishStatus: {
          required,
          isReallyAStatus: (value) => ['PUBLISHED', 'PUBLISH_NOW', 'PUBLISH_DELAY', 'NO_PUBLISH'].indexOf(value) !== -1,
        },
        content_html:{
          required: requiredUnless(function(val) {
            return this.feedItemData != null;
          })
        }
      }
    };
  },
  computed: {
    lastModifiedDate:{
      get(){
        if (this.feedItemData == null || this.feedItemData.date_modified == null){
          return '';
        }
        else {
          return moment.parseZone(this.feedItemData.date_modified).format('YYYY-MM-DD');
        }
      }
    },
    lastModifiedTime:{
      get(){
        if (this.feedItemData == null || this.feedItemData.date_modified == null){
          return '';
        }
        else {
          return moment.parseZone(this.feedItemData.date_modified).format('HH:mm:ss');
        }
      }
    },
    publishDate:{
      get(){
        if (this.feedItemData == null || this.feedItemData.date_published == null) {
          return '';
        }
        else {
          return moment.parseZone(this.feedItemData.date_published).format('YYYY-MM-DD');
        }
      },
      set(value) {//TODO: добавить сброс флажка на автозаполнение даты публикации
        if (this.feedItemData == null) {
          return;
        }
        let momentVal;
        if (this.feedItemData.date_published == null) {
          momentVal = this.feedItemData.date_published = moment(value);
        }
        else {
          let newDate = moment(value, 'YYYY-MM-DD');
          if (newDate.isValid()) {
            momentVal = moment.parseZone(this.feedItemData.date_published)
                .year(newDate.year())
                .month(newDate.month())
                .date(newDate.date());//угу это день месяца, без доков не понять
          }
          else {
            momentVal = null;
          }
        }
        if (momentVal != null) {
          if (momentVal.isBefore(this.curTime)) {
            momentVal = this.curTime;
          }
          this.feedItemData.date_published = momentVal.format(ISO_FORMAT);
        }
        else {
          this.feedItemData.date_published = null;
        }
      }
    },
    publishDateTime:{
      get(){
        if (this.feedItemData == null || this.feedItemData.date_published == null) {
          return '';
        }
        else {
          return moment.parseZone(this.feedItemData.date_published).format('YYYY-MM-DDTHH:mm');
        }
      },
      set(value) {//TODO: добавить сброс флажка на автозаполнение даты публикации
        if (this.feedItemData == null) {
          return;
        }
        let momentVal = moment(value);
        if (momentVal != null) {
          if (momentVal.isBefore(this.curTime)) {
            momentVal = this.curTime;
          }
          this.feedItemData.date_published = momentVal.format(ISO_FORMAT);
        }
        else {
          this.feedItemData.date_published = null;
        }
      }
    },
    publishTime: {
      // TODO: добавить сброс флажка на автозаполнение даты публикации
      get(){
        if (this.feedItemData == null || this.feedItemData.date_published == null) {
          return '';
        }
        else {
          return moment.parseZone(this.feedItemData.date_published).format('HH:mm:ss');
        }
      },
      set(time) {
        if (this.feedItemData == null) {
          return;
        }
        let newTime = moment(time, 'HH:mm:ss');
        if (!newTime.isValid()) {
          return;
        }
        let momentVal;
        if (this.feedItemData.date_published == null) {
          momentVal = moment()
              .second(newTime.second())
              .minute(newTime.minute())
              .hour(newTime.hour())
              .format(ISO_FORMAT);
        }
        else {
          momentVal = moment.parseZone(this.feedItemData.date_published)
              .second(newTime.second())
              .minute(newTime.minute())
              .hour(newTime.hour());
        }

        if (momentVal.isSameOrBefore(this.curTime)) {
          return;
        }
        this.feedItemData.date_published = momentVal.format(ISO_FORMAT);
      },
    },
    imageUrl() {
      if (this.fileUploadDataUrl == null) {
        return this.feedItemData.image;
      }
      else {
        return this.fileUploadDataUrl;
      }
    }
  },
  methods: {
    getCities() {
      return ident.cities().then(response => {
        this.cities = response.data.map((el) => {return {"text": el['cityName'], "value": el['cityNameTransliteration']}})
        this.cities.push({"text": "Всем", "value": null})
      })
    },
    onSubmit(){
      this.$v.feedItemData.$touch();
      if (this.submitting || this.$v.feedItemData.$anyError){
        return;
      }
      this.submitting = true;
      let startPromise;
      let submittedData = Object.assign({}, this.feedItemData);
      if (this.fileUploadData) {
        startPromise = () => {
          return feed.uploadFile(this.fileUploadData, submittedData.id, submittedData.id)
              .then((resp) => {
                console.log(resp);
                submittedData.image = resp.request.responseURL;
                return resp;
              });
        };
      }
      else {
        startPromise = () => Promise.resolve(true);
      }
      if (this.feedItemData.publishStatus === 'PUBLISH_DELAY' && submittedData.date_published == null) {
        submittedData.date_published = moment().format(ISO_FORMAT);
      }
      if (this.creating) {
        feed.createFeedItem(submittedData)
            .then(resp => {
              submittedData.id = resp.data.id;
              return startPromise();
            })
            .then(resp => {
              this.$router.push(`/feed/${submittedData.id}`);
            })
            .finally(() => {
              this.submitting = false;
            });
      }
      else {
        startPromise()
            .then(() => {
              return feed.updateFeedItem(submittedData);
            })
            .then(resp => {
              this.$router.push('/feed');
            })
            .finally(() => {
              this.submitting = false;
            });
      }
    },
    isValidURL(urlData){
      let url;

      try {
        url = new URL(urlData);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:" || url.protocol === 'data:';
    },
    loadImageFromAttachment(attFile) {
      if (attFile == null) {
        this.fileUploadDataUrl = null;
      }
      else {
        var fr = new FileReader();
        let vComp = this;
        fr.onload = function () {
          vComp.fileUploadDataUrl = fr.result;
        }
        fr.readAsDataURL(attFile);
      }
    },
    isInPast(dateVal) {
      let dateMoment = moment.parseZone(dateVal);
      return dateMoment.isBefore(this.curTime);
    },
    minPublishTime(timeVal) {
      if (timeVal == null) {
        return '00:00:00';
      }
      let dateVal = moment.parseZone(timeVal);
      if (!dateVal.isValid()) {
        return '00:00:00';
      }
      dateVal = dateVal.hour(0)
          .minute(0)
          .second(0);
      let curDate = moment(this.curTime).hour(0)
          .minute(0)
          .second(0);
      if (dateVal.isAfter(curDate)) {
        return '00:00:00';
      }
      return this.curTime.format('HH:mm:ss');
    },
    dropPublishDate() {
      this.feedItemData.date_published = null;
      this.fillPublishDate = false;
    },
    publishNow() {
      this.feedItemData.date_published = null;
      this.fillPublishDate = true;
    },
    reloadFeedItemData(id) {
      return feed.getSingle(id)
          .then(resp => {
            let item = Object.assign({
              id: null,
              content_html: null,
              title: null,
              url: null,
              image: null,
              date_published: null,
            },resp.data);
            if (item.date_modified != null) {
              let modifyMoment = moment.parseZone(item.date_modified).local();
              if (!modifyMoment.isValid()) {
                console.error('fetched date modified is invalid');
              }
              item.date_modified = modifyMoment.format(ISO_FORMAT);
            }
            if (item.date_published != null) {
              let publishMoment = moment.parseZone(item.date_published).local();
              if (!publishMoment.isValid()) {
                console.error('fetched publication date is invalid');
              }
              item.date_published = publishMoment.format(ISO_FORMAT);
            }
            this.feedItemData = item;
            this.fillPublishDate = false;
            this.htmlContent = (item.content_html != null && item.content_html !== "");
            document.title = this.$route.meta.title + ' ' + item.title;
            return Promise.resolve(this.feedItemData);
          });
    }
  }

}
</script>