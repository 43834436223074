import {AXIOS} from './backend-config';

export default {
    getFeedItemList(page, perPage){
        return AXIOS.get('/fullfeed.json', {
            params: {
                page,
                per_page: perPage
            }
        })
    },
    getSingle(id){
        return AXIOS.get(`/feed/item/${id}`);
    },
    createFeedItem(item){
        return AXIOS.post('/feed/item/', item);
    },
    deleteFeedItem(id){
        return AXIOS.delete(`/feed/item/${id}`);
    },
    updateFeedItem(item){
        return AXIOS.put(`/feed/item/${item.id}`, item);
    },
    uploadFile(fileBlob, itemId, prefix){
        return AXIOS.post( `/files/${prefix && prefix !== '' ? prefix + '_' : ''}${fileBlob.name}`,fileBlob, {
            headers: {
                'Content-Type': fileBlob.type,
                'update-image-feed-item': itemId,
            }
        })
    }
}
