import Vue from 'vue';
import Router from 'vue-router';
import Login from './components/Login';
import RootPage from './components/RootPage';
import FeedList from './components/feed/FeedList';
import FeedItemEdit from './components/feed/FeedItemEdit';
import store from './store';
Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {path: '/', component: RootPage, meta: {title: 'Главная страница' }},
        {path: '/login', component: Login,  meta: {title: 'Вход'}},
	{path: '/feed/', component: FeedList, meta: { title: 'Список уведомлений'}},
        {path: '/feed/create', component: FeedItemEdit, meta: { title: 'Создание уведомления'}, props: {creating: true}},
        {path: '/feed/:id', component: FeedItemEdit, meta: { title: 'Редактирование уведомления'}}
    ]
});

router.beforeEach((to,from, next) => {
    const publicPages = [/^\/$/,/^\/login/];// /^\/feed$/, /^\/feed\/[^\s\/]+$/,/^\/feed\/create$/
    const authRequired = !publicPages.some(reg => reg.test(to.path));
    const loggedIn = store.refreshToken != null;
    if (!loggedIn && authRequired){
        return next('/login');
    }
    
    document.title = to.meta.title;
    next();
});

export default router;
