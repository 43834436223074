<template>
    <div>
        <h1 class="my-4">Сервис работы с новостным фидом/уведомлениями</h1>
        <p>Для создания или редактирования новостей/уведомлений перейдите в "Список уведомлений"</p>
    </div>
</template>
<script>

    export default {
        name: 'root-page',
        components: {
        },
        mounted(){
        },
        data() {
            return {};
        }
    };
</script>
