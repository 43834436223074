<template>
    <div>
        <div class="d-flex justify-content-center">
            <h3>Вход</h3>
        </div>
        <b-form @submit.prevent="onSubmit" method="post" class="d-block mx-auto col-8 col-md-7 col-lg-6 col-xl-5">
            <b-form-input name="login" ref="loginInput" placeholder="Логин" class="mt-2" />
            <b-form-input name="password" type="password" ref="passwordInput" placeholder="Пароль" class="mt-2" />
            <b-alert class="mt-2" variant="danger" :show="errorMessage != null" dismissible @dismissed="errorMessage=null">
                {{ errorMessage }}
            </b-alert>
            <b-button type="submit" class="mt-2 mx-auto form-control">Вход</b-button>
        </b-form>
    </div>
</template>

<script>
    import auth from '../modules/auth';
    export default {
        name: 'login',
        mounted(){
            if (this.$root.$data.refreshToken != null){
                this.$router.push('/');
            }
        },
        data() {
            return {
                login: '',
                password: '',
                errorMessage: null
            }
        },
        methods: {
            onSubmit(d){
                let formData = new FormData(d.target);
                let login = formData.get('login');
                this.errorMessage = null;
                auth.login(login, formData.get('password'))
                    .then(resp => {
                        this.$root.$data.refreshToken = resp.data['refresh_token'];
                        this.$root.$data.login = login;
                        this.$root.$data.tokenType = resp.data['token_type'];
                        this.$root.$data.authToken = resp.data['access_token'];

                        this.$router.push('/');
                    })
                    .catch(err => {
                        if (err.response.status == 403){
                            this.errorMessage = 'Введён неверный логин или пароль';
                        }
                    });
                return false;
            }
        }
    }
</script>
